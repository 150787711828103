import React, { useState } from "react";
import "./App.css";

function App() {
  const handleContactClick = () => {
    document.getElementById("contact-section").scrollIntoView(); 
  };

  const handleSolutionsClick = () => {
    document.getElementById("solutions-section").scrollIntoView();
  };

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    company: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await fetch(
        "https://script.google.com/macros/s/AKfycbxrZblSnS0-4aXhK56u1Wq-wg1eS9NX1au2ulSdmRDuMqb3P4lPDuTuWg2K9uJRFjjFGg/exec",
        {
          redirect: "follow",
          method: "POST",
          headers: {
            "Content-Type": "text/plain"
          },
          body: JSON.stringify(formData),
        }
      );
  
      console.log("Fetch response status:", response.status);
      const result = await response.json();
      console.log("Fetch result:", result);
  
      if (result.status === "success") {
        alert("Message sent successfully!");
        // Clear the form after successful submission
        setFormData({
          name: "",
          email: "",
          company: "",
          message: ""
        });
      } else {
        console.error("Failed response from server:", result);
        alert("Failed to send message.");
      }
    } catch (error) {
      console.error("Error sending message:", error);
      alert("Error sending message.");
    }
  };
  
  

  return (
    <div className="App">
      <header className="header">
        <div className="logo">
          <img src="/logo.png" alt="Vector Hive Logo" />
        </div>
        <nav className="nav">
          <ul>
            <li onClick={handleSolutionsClick}>Solutions</li>
            <li onClick={handleContactClick}>Contact</li>
          </ul>
        </nav>
      </header>

      {/* Hero Section */}
      <section className="hero">
        <div className="overlay"></div>
        <div className="hero-content">
          <p className="subtitle">Empowering Your Vision with Software Solutions</p>
          <h1>
            We partner with businesses to build scalable, high-impact digital
            products that drive growth and success.
          </h1>
          <button className="cta" onClick={handleSolutionsClick}>Explore Our Services</button>
        </div>
      </section>

      {/* Info Section */}
      <section className="info">
        <div className="info-image">
          <img src="/secondpage.png" alt="Dashboard" />
        </div>
        <div className="info-text">
          <h3>Prototypes That Inspire. Products That Scale</h3>
          <p>
            We craft MVPs, prototypes, and production-ready solutions—
            specializing in web apps, custom LLMs, and sleek designs to bring
            your ideas to life.
          </p>
        </div>
      </section>

      {/* Solutions Section */}
      <section id="solutions-section" className="solutions">
        <div className="solutions-container">
          <div className="solution-card">
            <h3>Creating Stunning MVPs</h3>
            <ul>
              <li>Rapid Prototyping</li>
              <li>User-Centric Design</li>
              <li>Scalable Architecture</li>
              <li>Iterative Development</li>
            </ul>
          </div>
          <div className="solution-card">
            <h3>Backend Development</h3>
            <ul>
              <li>API Development</li>
              <li>Database Management</li>
              <li>Cloud Integration</li>
              <li>Security Protocols</li>
            </ul>
          </div>
          <div className="solution-card">
            <h3>Frontend Development</h3>
            <ul>
              <li>Responsive Design</li>
              <li>Interactive UI/UX</li>
              <li>Performance Optimization</li>
              <li>Cross-Browser Compatibility</li>
            </ul>
          </div>
          <div className="solution-card">
            <h3>Large Language Models (LLMs) Integration</h3>
            <ul>
              <li>Custom AI Solutions</li>
              <li>Natural Language Understanding</li>
              <li>Fine Tuning</li>
              <li>Chatbot Integration</li>
            </ul>
          </div>
        </div>
      </section>
      <section id="contact-section" className="contact">
        <div className="contact-header">
          <h2>Contact Us</h2>
          <p>Have any questions or inquiries? We'd love to hear from you.</p>
        </div>
        <div className="contact-form-container">
          <input
            type="text"
            name="name"
            placeholder="Name"
            value={formData.name}
            onChange={handleChange}
          />
          <input
            type="email"
            name="email"
            placeholder="Email"
            value={formData.email}
            onChange={handleChange}
          />
          <input
            type="text"
            name="company"
            placeholder="Company"
            value={formData.company}
            onChange={handleChange}
          />
          <textarea
            name="message"
            placeholder="Message"
            value={formData.message}
            onChange={handleChange}
          />
          <button className="contact-btn" onClick={handleSubmit}>
            Send Message
          </button>
        </div>


      <div className="footer">
        <p>© 2024 Vector Hive LLC. All rights reserved.</p>
      </div>
  </section>
    </div>
  );
}

export default App;

